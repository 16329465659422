export const shoes = [
  {
    id: "1",
    link: "/Shoes/RS011",
    code: "CI-RS011",
    detail: "2 Layer Nomex Leather Shoes",
    imgPath: [require("../../../../assets/RS011.JPG")],
    material: "Double Layer Nomex Shoes",
    leather: "Double Layer Nomex Shoes",
    insidelayer: "Inside Single Layer Nomex Lining",
    laces: "Nomex Laces",
    strap: "Double Velcro Strap",
    sole: "Moulded Rubber Sole",
  },
  {
    id: "2",
    link: "/Shoes/RS022",
    code: "CI-RS022",
    detail: "Double Layer Nomex Shoes",
    imgPath: [require("../../../../assets/RS022.JPG")],
    material: "Double Layer Nomex Shoes",
    leather: "Made of Suede Leather",
    insidelayer: "Inside Single Layer Nomex Lining",
    laces: "Nomex Laces",
    strap: "Double Velcro Strap",
    sole: "Moulded Rubber Sole",
  },
  {
    id: "3",
    link: "/Shoes/RS012",
    code: "CI-KS012",
    detail: "2 Layer Nomex Leather Shoes",
    imgPath: [require("../../../../assets/RS012.JPG")],
    material: "Double Layer Nomex Shoes",
    leather: "	Made of Leather Cow Hide and Suede Leather",
    insidelayer: "Inside Single Layer Nomex Lining",
    laces: "Nomex Laces",
    strap: "Double Velcro Strap",
    sole: "Moulded Rubber Sole",
  },
  {
    id: "4",
    link: "/Shoes/RS023",
    code: "CI-RS023",
    detail: "2 Layer Nomex Leather Shoes",
    imgPath: [require("../../../../assets/RS023.JPG")],
    material: "Double Layer Nomex Leather Shoes",
    leather: "Made of Suede Leather",
    insidelayer: "Inside Single Layer Nomex Lining",
    laces: "Nomex Laces",
    strap: "Double Velcro Strap",
    sole: "Moulded Rubber Sole",
  },
];
